body {
  background: rgb(244, 244, 245);
  padding-bottom: 120px;
}
.header {
  padding: 32px;
  position: relative;
  &::before {
    content: '';
    top: 0;
    left: 0;
    display: block;
    position: absolute;
    height: 500px;
    width: 100%;
    background: #2f3b55;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0)
    );
    opacity: 0.2;
  }

  hgroup {
    text-align: center;
    svg {
      width: 32px;
      height: 32px;
      fill: #eeb38e;
      opacity: 0.7;
    }
    h1 {
      font-family: 'Noto Serif JP', serif;
      font-size: 54px;
      color: #eeb38e;
      line-height: 1;
    }
    h2 {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 300;
      color: #f0f0f0;
    }
    h3 {
      font-variant-numeric: tabular-nums;
      color: #f0f0f0;
      font-weight: 300;
      margin-top: 12px;
      span {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }

  .image {
    width: 100%;
    border-radius: 30px;
    margin: 32px 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }

  @media only screen and (min-width: 768px) {
    height: 464px;
    margin-bottom: 100px;
    &::before {
      height: 370px;
    }
    .wrapper {
      display: grid;
      grid-template-columns: 40% auto;
      grid-template-rows: 320px 60px;
      gap: 32px;
      grid-template-areas:
        'image main'
        'image info';
      .image {
        grid-area: image;
      }
      hgroup {
        grid-area: main;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        svg {
          margin-bottom: 20px;
        }
        h1 {
          font-size: 64px;
        }
        h3 {
          margin-top: 12px;
        }
      }
      .desc {
        grid-area: info;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .wrapper {
      display: grid;
      grid-template-columns: 340px auto;
      grid-template-rows: 320px 60px;
    }
  }
}

.wrapper {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
  z-index: 1;
  position: relative;
}

p {
  line-height: 1.2;
  margin-bottom: 16px;
  &.suttle {
    color: rgba(#2f3b55, 0.8);
    font-size: 14px;
  }
  &.zoom-link {
    overflow: hidden;
  }
}

a {
  font-weight: bold;
  display: inline-block;
  position: relative;
  color: #2f3b55;
  text-decoration: none;
  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 90%;
    height: 4px;
    background: #eeb38e;
    z-index: 0;
    transition: all ease-in-out 0.2s;
  }
  span {
    position: relative;
    z-index: 1;
  }

  &:hover {
    &::before {
      height: 8px;
      background: rgba(#eeb38e, 0.5);
    }
  }
}

article {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  p {
    text-indent: 16px;
    line-height: 1.8;
  }
  &.video-message {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    a {
      transition: all ease-in-out 0.2s;
      background: #2f3b55;
      padding: 8px 16px;
      color: rgba(#ffffff, 0.9);
      border-radius: 8px;
      &::before {
        display: none;
      }
      &:hover {
        color: #ffffff;
      }
    }
  }
  &.gofundme {
    font-weight: bold;
  }
  &.images {
    margin-top: 20px;
    margin-bottom: 20px;
    figure {
      width: 100%;
      border-color: #ffffff;
      border-style: solid;
      border-width: 14px 14px 28px 14px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14),
        0 6px 20px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
      img {
        width: 100%;
        display: block;
      }
      &.one {
        transform: rotate(3deg);
      }
      &.two {
        transform: rotate(-3deg);
      }
    }
    @media only screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;
      figure {
        width: 360px;
        &.one {
          transform: rotate(-3deg);
        }
        &.two {
          transform: rotate(3deg);
        }
      }
    }
    @media only screen and (min-width: 1024px) {
      figure {
        width: 420px;
      }
    }
  }
}
